import React, { memo, useEffect } from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { AppBar, Box, createStyles, Grid, IconButton, makeStyles,
    Toolbar, Typography, useMediaQuery, Drawer, Divider } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import useTranslate from '../common/useTranslate';
import { ClassesOverride } from '../common/style.type';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
    classes: ClassesOverride<typeof useStyles>;
}

function ElevationScroll(props: Props) {
    const { children, window, classes } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        className: trigger ? classes.MenuContainerHover : classes.MenuContainerTop
    });
}

const Header = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const minHD = useMediaQuery('(min-width: 1280px)');

    const activeDecorator = ({ isActive }: { isActive: boolean }) => {
        return classNames(classes.menuItem, isActive ? classes.activePage : "", "menuItem")
    };

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        minHD && handleDrawerClose();
    })

    return (
    <>
        <ElevationScroll {...props} classes={classes}>
            <AppBar className={classes.MenuContainerTop}>
                <Toolbar className={classes.toolbar}>
                    <IconButton title="Home" className={classes.homeButton} href="/">
                        <Box
                            className={classNames(classes.Logo,"Logo")}
                            // image="/logo_red.svg"
                            title="Home"
                        />
                    </IconButton>
                    <Grid container spacing={0} direction="row">
                        <Grid item >
                            <Typography id="header-title" variant="h5" component="div" className={classes.title} noWrap>
                                in4s GmbH - Individuelle Software Lösungen
                            </Typography>
                        </Grid>
                        <Grid item style={{flex: 1}} />
                    {
                        minHD ?
                        (
                            <>
                            <Grid item><NavLink className={activeDecorator} to={"/"} >{translate("Home", {_: "Home"})}</NavLink></Grid>
                            <Grid item><NavLink className={activeDecorator} to={"/services"} >{translate("Services", {_: "Leistungen"})}</NavLink></Grid>
                            <Grid item><NavLink className={activeDecorator} to={"/expertise"} >{translate("Expertise", {_: "Expertise"})}</NavLink></Grid>
                            <Grid item><NavLink className={activeDecorator} to={"/about"}>{translate("AboutUs", {_: "Über uns"})}</NavLink></Grid>
                            <Grid item><NavLink className={activeDecorator} to={"/contact"} >{translate("ContactUs", {_: "Kontakt"})}</NavLink></Grid>
                            {/* <Grid item style={{flex: 2}} /> */}
                            </>
                        )
                        :
                        (
                            <IconButton
                                edge="end"
                                className={classNames(classes.menuButton, open && classes.hide)}
                                color="inherit"
                                aria-label="menu"
                                onClick={handleDrawerOpen}
                            >
                                <MenuIcon />
                            </IconButton>
                        )

                    }
                    </Grid>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
        <Toolbar />
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{paper: classes.drawerPaper}}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronRightIcon color="primary"/>
                </IconButton>
            </div>
            <Divider />
            <NavLink className={activeDecorator} onClick={() => handleDrawerClose()} to={"/"}>{translate("Home", {_: "Home"})}</NavLink>
            <NavLink className={activeDecorator} onClick={() => handleDrawerClose()} to={"/services"} >{translate("Services", {_: "Leistungen"})}</NavLink>
            <NavLink className={activeDecorator} onClick={() => handleDrawerClose()} to={"/expertise"} >{translate("Expertise", {_: "Expertise"})}</NavLink>
            <NavLink className={activeDecorator} onClick={() => handleDrawerClose()} to={"/about"}>{translate("AboutUs", {_: "Über uns"})}</NavLink>
            <NavLink className={activeDecorator} onClick={() => handleDrawerClose()} to={"/contact"} >{translate("ContactUs", {_: "Kontakt"})}</NavLink>
        </Drawer>
    </>
    )
}

export default memo(Header);

const useStyles = makeStyles(
    ({palette, spacing, mixins}) => createStyles({
        MenuContainerTop: {
            color: palette.text.primary,
            backgroundColor: "transparent",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color ease-in 100ms",
            "& .Logo": {
                backgroundImage: "url(\"/logo_red.svg\")",
            },
            "& .menuItem": {
                color: palette.text.primary,
            }
        },
        MenuContainerHover: {
            backgroundColor: palette.primary.main,
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color ease-out 100ms",
            "& .Logo": {
                backgroundImage: "url(\"/logo_white.svg\")",
            },
            "& .menuItem": {
                color: palette.primary.contrastText,
            },
            "& .menuItem:after": {
                backgroundColor: palette.primary.contrastText,
            }
        },
        Logo: {
            display: "block",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
        },
        toolbar: {
            minWidth: 720,
            paddingRight: 24,
            "& .Logo": {
                height: "2.5rem",
                width: "2.5rem",
            },
            "& .MuiGrid-container": {
                flexWrap: "nowrap",
            },
            "& .MuiGrid-item a": {
                whiteSpace: "nowrap",
                height: spacing(8),
            },
            "& .MuiGrid-item": {
                display: "inline-grid",
                alignContent: "center",
            }
        },
        homeButton: {
            marginLeft: '0.2em',
            marginRight: '0.2em',
        },
        title: {
            width: "28rem",
            maxWidth: "calc(100vw - 64px - 24px - 64px)",
        },
        menuItem: {
            fontSize: "1.2rem",
            flexShrink: 0,
            display: "flex",
            position: "relative",
            alignItems: "center",
            textDecoration: "none",
            cursor: "pointer",
            padding: spacing(1, 3),
            transition: "0.2s ease-out, scale 50ms ease-in",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "1px",
            "&:after": {
                content: "''",
                display: "block",
                position: "absolute",
                bottom: 0,
                left: spacing(3 / 2),
                width: `calc(100% - ${spacing(3)}px)`,
                height: 2,
                transform: "scale(0, 1)",
                backgroundColor: palette.text.primary,
            },
            "&:hover": {
                // transform: "scale(1, 1.2)",
                "&:after": {
                    transform: "scale(1, 1)",
                }
            },
            "&:not(:first-child)": {
                marginLeft: spacing(2),
            }
        },
        activePage: {
            "&:after": {
                transform: "scale(1, 1)",
            },
        },
        menuButton: {
            position: "absolute",
            left: "calc(100vw - 56px)",
            top: spacing(1),
            padding: spacing(0.5, 0),
            width: spacing(6),
            height: spacing(6),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            "& .menuItem": {
                marginLeft: spacing(1),
                color: palette.text.primary
            },
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
          display: 'flex',
          alignItems: 'center',
          padding: spacing(0, 1),
          // necessary for content to be below app bar
          ...mixins.toolbar,
          justifyContent: 'flex-start',
          color: palette.primary.main
        },
    }),
    { name: 'HeadBar' }
);

const drawerWidth = "14rem";
