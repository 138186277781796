import React from "react";
import { Box, Container, Typography } from "@material-ui/core";

import HomeBanner from './components/HomeBanner';
import MoreWithYourData from "./components/MoreWithYourData";
import HomeErwartungen from "./components/HomeErwartungen";
import Title from "../../common/Title";

export const HomePage = () => {
    return (
        <Box display={"flex"} flexDirection="column" minHeight={"75vh"}>
            <Title title='in4s GmbH - Ihr Partner für Digitalisierung' />
            <HomeBanner />
            <Container maxWidth="xl">
                <MoreWithYourData />
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h2" color="primary">Das erwartet Sie bei uns</Typography>
                    <HomeErwartungen />
                </Box>
            </Container>
        </Box>
    );
}

export default HomePage