import React from "react";
import { Box, Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import ContactForm from "./components/ContactForm";
import classNames from "classnames";
import Title from "../../common/Title";

const ContactPage = () => {
    const classes = useStyles();
    return (
        <>
            <Title title='in4s GmbH - Kontaktieren Sie uns' />
            <div className={classNames(classes.banner)}>
                <section />
            </div>
            <Box
                display={"flex"}
                flexDirection="column"
                className={classNames(classes.root)}
            >
                <Container maxWidth="lg" >
                    <Typography variant="h3" color="primary">Haben wir Ihr Interesse geweckt?</Typography>
                    <Typography variant="h5">Nehmen Sie einfach Kontakt mit uns auf.</Typography>
                    <Box
                        display={"grid"}
                        gridTemplateColumns="50% 50%"
                        gridGap={32}
                        className={classNames(classes.grid)}
                    >
                        <Box padding={4}>
                            <Typography variant="button">Schreiben Sie uns eine Nachricht:</Typography>
                            <ContactForm />
                        </Box>
                        <Box padding={4}>
                            <Typography variant="button">Oder kontaktieren Sie uns schriftlich:</Typography>
                            <Typography variant="body1" style={{ marginTop: "1.0em" }}>in4s GmbH</Typography>
                            <Typography variant="body1">Hegelstraße 39</Typography>
                            <Typography variant="body1">39104 Magdeburg</Typography>
                            <Typography variant="body1" style={{ marginBottom: "3.0em" }}>Deutschland</Typography>

                            {/* <Typography variant="button">Oder telefonisch:</Typography>
                            <Typography variant="body1" style={{ marginTop: "1.0em" }}>
                                <PhoneIcon /><span style={{ marginLeft: 8 }}>+49 (0) Telefonnummer</span>
                            </Typography> */}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    )
}


export default ContactPage;

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => createStyles({
    root: {
        margin: spacing(12, 0, 10),
    },
    banner: {
        margin: spacing(2, 0),
        display: "inline-block",
        width: "100%",
        height: 380,
        "& section": {
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100vmax auto",
            background: "url('/images/banners/contact.png')",
            backgroundPositionY: "20%",
        }
    },
    grid: {
        [breakpoints.down(800)]: {
            gridTemplateColumns: "1fr",
        }
    }
}),
    {
        name: "Contact"
    });