import React from "react";
import { Box, createStyles, Grid, makeStyles, Typography, Card, Container } from "@material-ui/core";
import classNames from "classnames";
import LeistungenThemen from "./components/LeistungenThemen";
import Title from "../../common/Title";

const ServicesPage = () => {
    const classes = useStyles();
    return (
        <>
            <Title title='in4s GmbH - Individuelle Software Lösungen' />
            <section className={classNames(classes.banner)} />
            <Container maxWidth="lg">
                <Box className={classNames(classes.root)} display={"flex"} flexDirection="column" minHeight={"75vh"} padding={1} alignItems="center">

                    <Grid container alignItems="center" direction='column'>
                        <Typography variant="h3" color="primary">Wir bieten:</Typography>
                        <LeistungenThemen />

                        <Typography variant="h3" color="primary">Wir begleiten Sie</Typography>
                        <Typography variant="h5" className="subHeaderSpacing" color="primary">von der:</Typography>
                        <Box className={classNames(classes.boxes)}>
                            <Typography variant="h4" >Beratung</Typography>
                            <Typography variant="body1" color='textSecondary' >
                                Neben einer Beratung, wie sich die Prozesse in Ihrem Unternehmen digitalisieren und automatisieren lassen, bieten wir:
                                <ul>
                                    <li>Machbarkeitsstudien für Ihre speziellen Anforderungen</li>
                                    <li>Beratung zum Einsatz und zur Nutzung von Open Source und proprietären Lösungen</li>
                                    <li>Seminare und Schulungen zu Themen der Digitalisierung</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Typography variant="h5" className="subHeaderSpacing" color="primary">über die:</Typography>
                        <Box className={classNames(classes.boxes)}>
                            <Typography variant="h4" >Softwareentwicklung</Typography>
                            <Card className={classNames(classes.card, classes.lifecycle)} elevation={0} />
                        </Box>
                        <Typography variant="h5" className="subHeaderSpacing" color="primary">bis hin zu:</Typography>
                        <Box className={classNames(classes.boxes)}>
                            <Typography variant="h4" >Wartung &amp; Support</Typography>
                            <Typography  variant="body1" color='textSecondary' >
                                Flexibel gestaltete Wartungs- und Supportverträge sichern Ihnen auch nach Fertigstellung Ihrer Softwarelösung die Pflege und den Schutz Ihrer Daten zu.
                                Je nach Priorität der Anwendung für Sie, bieten wir Ihnen den passenden Tarif zur Sicherstellung:
                                <ul>
                                    <li>der Sicherheits Ihres Systems durch regelmäßige Updates</li>
                                    <li>der Ausfallsicherheit Ihres Systems durch Vorhaltung von Backupsystemen</li>
                                    <li>regelmäßiger Backups Ihrer Daten</li>
                                </ul>
                            </Typography>
                        </Box>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}


export default ServicesPage;

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => createStyles({
    root: {
        margin: spacing(12, 0, 10),
        padding: 0,
        "& .subHeaderSpacing": {
            margin: spacing(6, 0)
        }
    },
    banner: {
        display: "inline-block",
        width: "100%",
        height: "20rem",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        background: "url('/images/banners/service.jpg')",
        backgroundPosition: "center",
        margin: spacing(4, 0),
        backgroundColor: palette.primary.main,
    },
    boxes: {
        boxShadow: "0 0.0em 0.75em 0.0em #ddd",
        backgroundColor: palette.background.paper,
        border: "2px solid black",
        borderColor: palette.grey[300],
        width: "100%",
        margin: spacing(1, 0),
        padding: spacing(2, 0),
        position: "relative",
        "& h4": {
            position: "absolute",
            marginTop: -spacing(5),
            padding: spacing(0.1, 0.5),
            left: "2rem",
            background: `linear-gradient(to bottom, rgba(255,255,255,0.0) 0%, rgba(255,255,255,1.0) 45%)`,
            // margin: spacing(2, 0),
            color: palette.primary.main,
            // textTransform: "uppercase",
            textAlign: "center",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "80vw"
        },
        "& .MuiTypography-body1": {
            marginTop: spacing(2),
            padding: spacing(0, 6),
        }
    },
    card: {
        width: "100%",
        textAlign: "center",
        margin: spacing(1, 0),
        padding: spacing(2),
    },
    lifecycle: {
        minHeight: "calc(100vw * 1001 / 2225)",
        background: "white url('/images/lifecycle.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        [breakpoints.up("lg")]: {
            minHeight: "calc(1280px * 1001 / 2225)",
        }
    }
}),
    {
        name: "Services"
    });