import React from "react";
import { Box, Card, CardContent, CardMedia, createStyles, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";


const TextDokumente = () => {
    const classes = useStyles();
    return (
        <Box className={classNames(classes.root)} >
            <Card className={classNames(classes.mediaLeft)} elevation={0}>
                <CardContent className="mirror">
                    <div className="framing">
                        <CardMedia image="/images/lhs/01-start.png" />
                        <CardMedia className="diag" image="/images/lhs/01-start-b.png" />
                    </div>
                </CardContent>
                <CardContent>
                    <Typography variant="h5">Das Regulatorisches Monitoring ...</Typography>
                    <Typography paragraph>
                        <ul>
                            <li>Regulatorische Dokumente werden regelmäßig synchronisiert, importiert und de-dupliziert.</li>
                            <li>Versionierung und Änderungsanalyse geben einen Überblick über die Änderungen in den Regularien.</li>
                            <li>Mehrere hundert Strukturdatenquellen in 26 verschiedenen Sprachen wurden an das System angeschlossen,
                                um regelmäßig über neue regulatorische Entwicklungen informiert zu werden.
                            </li>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.mediaRight)} elevation={0}>
                <CardContent className="mirror">
                    <div className="framing">
                    <CardMedia image="/images/lhs/03-content_analysis.png" />
                    </div>
                </CardContent>
                <CardContent>
                    <Typography variant="h5">... mit Content-Analyse</Typography>
                    <Typography paragraph>
                        Damit dem Nutzer die thematische Einordnung und Relevanzbeurteilung auf den ersten Blick gelingt.
                    </Typography>
                    <Typography paragraph>
                        Er ist so in der Lage, die regulatorischen Aktivitäten innerhalb kürzester Zeit zu beurteilen.
                    </Typography>
                    <Typography paragraph>
                        <ul>
                            <li><em>Klassifikation</em> und Einordnung in Ontologien</li>
                            <li>Zusammenfassung des Dokumenteninhalts anhand der wichtigsten <em>Schlüsselbegriffe</em></li>
                            <li><em>Relevance Scores</em> um die Wichtigkeit des Dokuments gegenüber anderen zu zeigen</li>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.mediaLeft)} elevation={0}>
                <CardContent className="mirror">
                    <div className="framing">
                    <CardMedia image="/images/lhs/02-inbox.png" />
                    <CardMedia className="diag" image="/images/lhs/02-inbox-labs.png" />
                    </div>
                </CardContent>
                <CardContent>
                    <Typography variant="h5">... und Neuigkeiten</Typography>
                    <Typography paragraph>
                        Jeder unserer Nutzer sieht die für ihn relevanten geändertern und neuen Regularien auf den ersten Blick:
                    </Typography>
                    <Typography paragraph>
                        <ul>
                            <li>Eingeordnet in Themenbereiche</li>
                            <li>Für frei konfigurierbare Zeiträume, z.B. dem letzten Monat</li>
                            <li>Strukturiert nach Urheber und Kategorien</li>
                            <li>Filterbar nach Jurisdiktionen</li>
                            <li>Verknüft mit Wissenshierarchien</li>
                            <li>Selbstlernend und angepasst an den konkreten Wissensbedarf</li>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    )
}

export default TextDokumente;

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => createStyles({
    root: {
        backgroundColor: palette.background.default,
        borderColor: palette.primary.dark,
        width: "100%",
        margin: spacing(1, 0),
        padding: spacing(2, 0),
        "& h5": {
            margin: spacing(2, 0),
            color: palette.primary.main,
            textTransform: "uppercase",
            textAlign: "center",
            width: "70rem",
            maxWidth: "90%",
            [breakpoints.down("md")]: {
                margin: spacing(2, "auto"),
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
        "& .MuiTypography-body1": {
            padding: spacing(0, 6),
            color: palette.text.secondary,
            width: "70rem",
            maxWidth: "95%",
            margin: 0,
            [breakpoints.down("md")]: {
                margin: spacing(0, "auto"),
            },
        },
    },
    mediaLeft: {
        display: "flex",
        flexDirection: "row",
        "& .MuiCardMedia-root, & .MuiCardContent-root": {
            width: "50%",
            height: "30rem",
            [breakpoints.down("md")]: {
                width: "100%",
            }
        },
        "& .MuiTypography-body1, & h5": {
            marginRight: "auto",
        },
        "& .mirror": {
            backgroundColor: palette.grey[300],
            padding: spacing(8),
            position: "relative",
            paddingLeft: `calc(50% - 685px - ( 2 * ${spacing(8)}px))`,
            overflow: "hidden",
            [breakpoints.down("md")]: {
                paddingLeft: spacing(8),
                height: "28rem",
            },
        },
        "& .framing": {
            position: "relative",
            [breakpoints.down("md")]: {
                width: 690,
                margin: "0 auto",
            }
        },
        "& .mirror .MuiCardMedia-root": {
            width: "500px",
            height: "254px",
            backgroundSize: "contain",
            position: "relative",
            borderRadius: "8px",
            boxShadow: "0 0.0em 0.35em 0.0em #666"
        },
        // "& .mirror .MuiCardMedia-root:hover": {
        //     zIndex: 3,
        // },
        "& .mirror .MuiCardMedia-root.diag": {
            position: "absolute",
            top: "85px",
            left: "185px",
            zIndex: 2,
        },
        "& .mirror .MuiCardMedia-root:after, & .mirror .MuiCardMedia-root:before": {
            content: "''",
            position: "absolute",
            display: "block",
            width: "inherit",
            bottom: "-105px",
            height: "104px",
        },
        "& .mirror .MuiCardMedia-root:after": {
            background: "inherit",
            backgroundSize: "cover",
            backgroundPositionY: "bottom",
            transform: "scaleY(-1)",
            borderRadius: "8px",
        },
        "& .mirror .MuiCardMedia-root:before": {
            zIndex: 1,
            background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5), ${palette.grey[300]})`,
        },
        [breakpoints.down("md")]: {
            flexDirection: "column",
            "& .MuiCardContent-root": {
                height: "inherit",
            },
        },
    },
    mediaRight: {
        display: "flex",
        flexDirection: "row-reverse",
        "& .MuiCardMedia-root, & .MuiCardContent-root": {
            width: "50%",
            height: "30rem",
            [breakpoints.down("md")]: {
                width: "100%",
            }
        },
        "& .MuiTypography-body1, & h5": {
            marginLeft: "auto",
        },
        "& .mirror": {
            backgroundColor: palette.grey[300],
            padding: spacing(8),
            position: "relative",
            paddingRight: `calc(50% - 685px - ( 2 * ${spacing(8)}px))`,
            [breakpoints.down("md")]: {
                paddingRight: spacing(8),
                height: "28rem",
            },
        },
        "& .framing": {
            position: "relative",
            [breakpoints.down("md")]: {
                width: 690,
                margin: "0 auto",
            }
        },
        "& .mirror .MuiCardMedia-root": {
            width: "500px",
            height: "254px",
            backgroundSize: "contain",
            position: "relative",
            borderRadius: "8px",
            boxShadow: "0 0.0em 0.35em 0.0em #666"
        },
        // "& .mirror .MuiCardMedia-root:hover": {
        //     zIndex: 3,
        // },
        "& .mirror .MuiCardMedia-root.diag": {
            position: "absolute",
            top: "85px",
            left: "185px",
            zIndex: 2,
        },
        "& .mirror .MuiCardMedia-root:after, & .mirror .MuiCardMedia-root:before": {
            content: "''",
            position: "absolute",
            display: "block",
            width: "inherit",
            bottom: "-105px",
            height: "104px",
        },
        "& .mirror .MuiCardMedia-root:after": {
            background: "inherit",
            backgroundSize: "cover",
            backgroundPositionY: "bottom",
            transform: "scaleY(-1)",
            borderRadius: "8px",
        },
        "& .mirror .MuiCardMedia-root:before": {
            zIndex: 1,
            background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5), ${palette.grey[300]})`,
        },
        [breakpoints.down("md")]: {
            flexDirection: "column",
            "& .MuiCardContent-root": {
                height: "inherit",
            },
        },
    }
}),
{
    name: "ExpertiseText",
});