import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import ErrorPage from './common/ErrorPage';
import StartPage from './pages/home/Home';
import AboutPage from './pages/about/AboutPage';
import ServicesPage from './pages/services/ServicesPage';
import ExpertisePage from './pages/expertise/ExpertisePage';
import ContactPage from './pages/contact/ContactPage';
import DatenschutzPage from './pages/datenschutz/DatenschutzPage';
import ImpressumPage from './pages/impressum/ImpressumPage';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<StartPage />} />
          <Route path="impressum" element={<ImpressumPage />} />
          <Route path="datenschutz" element={<DatenschutzPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route path="expertise" element={<ExpertisePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="impressum" element={<ContactPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.querySelector('#root'),
);
