import React from "react";
import { Box, Card, CardContent, CardMedia, Container, Grid, Typography, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import classNames from "classnames";
import Title from "../../common/Title";

const AboutPage = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
        <Title title='in4s GmbH - Das Team' />
        <section className={classNames(classes.banner)} />
        <Container maxWidth="xl">
        <Grid container alignItems="center" direction='column' >
            <Typography variant="h3" style={{ margin: theme.spacing(6, 0, 2), color: theme.palette.primary.contrastText }}>Ihre Ansprechpartner</Typography>
            <Box display={"flex"} flexDirection={"row"} flexWrap="wrap" className={classNames(classes.root)}>
                <Card className={classNames(classes.member)} elevation={4}>
                    <CardMedia >
                       <img src="images/team/slanger2.jpg" alt="Stefan Langer" />
                    </CardMedia>
                    <CardContent>
                        <Typography variant="body1">Stefan Langer</Typography>
                        <Typography variant="body2">Gründungsmitglied &amp; Geschäftsführer</Typography>
                    </CardContent>
                </Card>

                <Card className={classNames(classes.member)} elevation={4}>
                    <CardMedia >
                        <img src="images/team/mgenzmehr2.jpg" alt="Marcel Genzmehr" />
                    </CardMedia>
                    <CardContent>
                        <Typography variant="body1">Marcel Genzmehr</Typography>
                        <Typography variant="body2">Gründungsmitglied &amp; Geschäftsführer</Typography>
                    </CardContent>
                </Card>

                <Card className={classNames(classes.member)} elevation={4}>
                    <CardMedia >
                        <img src="images/team/anuernberger.jpg" alt="Prof. Dr. Andreas Nürnberger" />
                    </CardMedia>
                    <CardContent>
                        <Typography variant="body1">Prof. Dr. Andreas Nürnberger</Typography>
                        <Typography variant="body2">Gründungsmitglied &amp; Wissenschaftlicher Berater</Typography>
                    </CardContent>
                </Card>
            </Box>
            <Box className={classNames(classes.history)}>
                <Typography variant="body1">
                    2015 aus der Otto-von-Guericke Universität Magdeburg heraus gegründet, entwickelt
                </Typography>
                <Typography variant="h4">
                    in<span className={classNames(classes.blue)}>4</span>s
                </Typography>
                <Typography variant="body1">
                    zusammen mit Ihnen angefangen mit ersten Design-Prototypen bis zum einsatzfähigen Softwareprodukt
                </Typography>

                <Box className={classNames(classes.boxes)}>
                    <CheckIcon className={classNames(classes.check)} />
                    <Typography variant="h5">
                        <span className={classNames(classes.blue)}>in</span>novative
                    </Typography>
                    <Typography variant="body1">
                        Such- und Recherchesysteme für lokale Daten, Web-Dokumente oder kombiniert
                    </Typography>
                </Box>

                <Box className={classNames(classes.boxes)}>
                    <CheckIcon className={classNames(classes.check)} />
                    <Typography variant="h5">
                        <span className={classNames(classes.blue)}>in</span>telligente
                    </Typography>
                    <Typography variant="body1">
                        Verfahren zur Analyse heterogenere Datenbestände: Dokumente, Bilder, Ontologien, uvm.
                    </Typography>
                </Box>

                <Box className={classNames(classes.boxes)}>
                    <CheckIcon className={classNames(classes.check)} />
                    <Typography variant="h5">
                        <span className={classNames(classes.blue)}>in</span>teraktive
                    </Typography>
                    <Typography variant="body1">
                        Web-Applikationen: schnell, benutzerfreundlich, mobil
                    </Typography>
                </Box>

                <Box className={classNames(classes.boxes)}>
                    <CheckIcon className={classNames(classes.check)} />
                    <Typography variant="h5">
                        <span className={classNames(classes.blue)}>in</span>dividuell
                    </Typography>
                    <Typography variant="body1">
                        individuell maßgeschneidert auf ihre Bedürfnisse
                    </Typography>
                </Box>
            </Box>
        </Grid>
        </Container>
        </>
    )
}


export default AboutPage;

const useStyles = makeStyles(({palette, spacing}) => createStyles({
    root: {
        marginBottom: spacing(5),
    },
    banner: {
        position: "absolute",
        zIndex: -1,
        display: "inline-block",
        width: "100%",
        height: "20rem",
        margin: spacing(2, 0),
        backgroundColor: palette.primary.main,
    },
    history: {
        padding: spacing(4),
        "& > h4": {
            width: "100%",
            textAlign: "center",
            "& span": {
                paddingTop: "1rem",
            }
        },
    },
    member: {
        borderRadius: 6,
        margin: "1rem",
        marginBottom: 100,
        width: 485,
        height: 600,
        overflow: "visible",
        "& .MuiTypography-body2": {
            // fontSize: "1.1rem",
        },
        "& > .MuiCardMedia-root": {
            backgroundColor: "#F8F8F8",
            textAlign: "center",
            borderRadius: "inherit",
        },
        "& > .MuiCardMedia-root svg, & > .MuiCardMedia-root img": {
            height: 520,
            width: 440,
            margin: 20,
            marginBottom: 5,
            border: "1px solid lightgray",
            background: "linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(248,248,248,1) 100%)",
            color: "lightgray"
        },
        "& > .MuiCardContent-root": {
            width: 420,
            height: 100,
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            borderRadius: 8,
            margin: "auto",
        },
    },
    boxes: {
        boxShadow: "0 0.0em 0.75em 0.0em #ddd",
        backgroundColor: palette.background.paper,
        width: "100%",
        margin: spacing(3, 0),
        padding: spacing(2),
        position: "relative",
        "& h5, & p": {
            marginLeft: "5rem",
        },
    },
    blue: {
        color: palette.primary.main,
        fontWeight: "bold",
        fontSize: "2rem",
    },
    check: {
        margin: "center 0",
        position: "absolute",
        color: palette.primary.light,
        height: "4rem",
        width: "5rem",
    }


}),
{
    name: "AboutPage"
});