import React from "react";
import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import Technologies from "./components/Techologies";
import classNames from "classnames";
import Title from "../../common/Title";
import TextDokumente from "./components/TextDokumente";
import VisDokumente from "./components/VisDokumente";

const MAX_WIDTH_TEXT: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false = "lg";

const ExpertisePage = () => {
    const classes = useStyles();
    return (
        <Box
            display={"flex"}
            flexDirection="column"
            minHeight={"75vh"}
            alignItems="center"
            className={classNames(classes.root)}

        >
            <Title title='in4s GmbH - Innovative Software Lösungen' />
            <section className={classNames(classes.banner)} />
            <Container maxWidth={MAX_WIDTH_TEXT} >
                <Typography variant="body1" color="textSecondary" paragraph>
                    Unser Unternehmen beschäftigt sich hauptsächlich mit der Analyse und Darstellung von Daten,
                    die Ihrem Unternehmen einen echten Mehrwert bieten. Unser Ziel ist dabei die technische Unterstützung oder wenn mögliche Vollständige Automatisierung der Prozesse in Ihrem Unternehmen.
                    So sparen Sie Zeit für Arbeiten, die vor allem ihr Know How und nicht nur Ihren Fleiß erfordern.
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    Daten können dabei von völlig unterschiedlicher Art sein, z. B.:
                </Typography>
            </Container>

            <Box
                display="grid"
                gridGap={32}
                gridTemplateColumns="1fr 1fr"
                className={classNames(classes.toc)}
            >
                <Card className={classNames(classes.card)}>
                    <CardMedia image="/images/expertise/recherche.png" className="teaser" />
                    <CardContent>
                        <CardHeader title={<Typography variant="h3" color="primary" className="title">Textbasierte Daten</Typography>} />
                        <Typography paragraph color="textSecondary">
                            Wir sammeln, verarbeiten und analysieren <span className="emphasize">natürlichsprachige oder maschinell erstellte Dokumente</span> in allen gängigen Formarten mit Anhängen oder Verlinkungen.
                            Ob Zusammenfassungen, Einordnungen, Durchsuchbarmachung oder Verknüpfung untereinander:
                        </Typography>
                    </CardContent>
                    <CardActions  className="more">
                        <a href="#textDesc"><Typography variant="button">Mehr&nbsp;&gt;</Typography></a>
                    </CardActions>
                </Card>

                <Card className={classNames(classes.card)}>
                    <CardMedia image="/images/expertise/bilddaten.png" className="teaser" />
                    <CardContent>
                        <CardHeader title={<Typography variant="h3" color="primary" className="title">Visuelle Daten</Typography>} />
                        <Typography color="textSecondary" paragraph>
                            <span className="emphasize">Industrielle Fotografien oder Bildserien</span> werden in verschiedenen Szenarien in der Industrie eingesetzt. Ob Qualitätsprüfung, Statistik oder andere Analysen,
                            wir binden Ihre industriellen Kameras z. B. von Baumer, IDS oder anderen Anbietern direkt an unsere Systeme an.
                        </Typography>
                    </CardContent>
                    <CardActions className="more">
                        <a href="#visDesc"><Typography variant="button">Mehr&nbsp;&gt;</Typography></a>
                    </CardActions>
                </Card>

                <Typography variant="h4" paragraph color="primary" className={classNames("citation", classes.jumpTop)}>
                &bdquo;Mit uns haben Sie immer den perfekten Überblick.&ldquo;
                </Typography>

                <Typography variant="h4" color="primary" paragraph className={classNames("citation", classes.jumpUp)}>
                &bdquo;Die Analyse und übersichtliche Darstellung ist unser täglich Brot.&ldquo;
                </Typography>
            </Box>

            {/* TECHNOLOGY */}
            <Typography variant="h2" color="primary">Auswahl aus unserem Technology-Stack</Typography>
            <Container maxWidth={MAX_WIDTH_TEXT} >
                <Typography variant="body1" color="textSecondary">
                    Jedes Projekt benötigt ein spezielles Set aus Technologien, die dem jeweiligen Zweck am besten dienen.
                    Unsere Unternehmensphilosophie ist ständige Weiterbildunng um unsere Produkte optimal auf den jeweiligen Zweck unserer Kunden anpassen zu können.
                    Trotzdem gibt es einige Technologien, die wir immer wieder einsetzen:
                </Typography>
            </Container>
            <Technologies />

            {/* RECHERCHESYSTEME */}
            {/* anchor for jumpto */}
            <a id="textDesc" style={{ visibility: "hidden" }} href=".">&nbsp;</a>
            <Typography variant="h2" color="primary">Textbasierte Daten</Typography>
            <Container maxWidth={MAX_WIDTH_TEXT} >
                <Typography variant="body1" color='textSecondary'>
                    <p>Wir sind Experten für Such- und Recherchetechnologien auf natürlichsprachigen Dokumenten.</p>
                    <p>
                    Mit <em>LegalHorizon Scanning</em> (LHS) haben wir für die LegalHorizon AG eine umfassende Software für
                    das regulatorische Monitoring in großen Unternehmen technisch konzipiert, als Forschungsprojekt mit der Otto-von-Guericke Universität Magdeburg koordiniert und federführend implementiert:
                    LHS synchronisiert selbständig seinen internen Datenbestand mit internationalen Gesetze, Gesetzesentwürfen, Urteilen der Rechtsprechung und anderen regulatorischen Dokumenten.
                    Die Dokumente werden thematisch sortiert, untereinander verknüft und bzgl. ihrer Relevanz für die jeweiligen Nutzer und ihre Unternehmen analysiert, aufbereitet und in leicht verständlicher Form
                    als Web-Applikation präsentiert.
                    </p>
                </Typography>
                <Typography variant="body1" color='textSecondary' style={{maxWidth: "65vw"}}>
                    Wichtigstes Feature von LHS ist:
                </Typography>
            </Container>
            <TextDokumente />

                {/* IMAGE ANALYSIS */}
            {/* anchor for jumpto */}
            <a id="visDesc" style={{ visibility: "hidden" }} href=".">&nbsp;</a>
            <Typography variant="h2" color="primary">Industrielle Bilddatenanalyse</Typography>
            <Container maxWidth={MAX_WIDTH_TEXT} >
                <Typography variant="body1" color='textSecondary' style={{maxWidth: "65vw"}}>
                    Ob Bilddaten aus Fotographien oder aus Videoaufnahmen,
                    durch langjährige Zusammenarbeit mit industriellen Partnern in der Bildanalyse zur Unterstützung der Qualitätskontrolle haben wir viel Erfahrung sammeln können.
                    Unter Anderem bei der Anbindung und Programmierung von Industriekameras z.&nbsp;B. von IDS oder Baumer,
                    als auch zu Verfahren zur Synchronisation und Positionierung der Bilddaten von Objekten mit virtuellen Räumen, unter Verwendung von Sensoren wie Drehgebern oder Distanzmessern.
                </Typography>
            </Container>
            <VisDokumente />

        </Box>
    )
}


export default ExpertisePage;

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => createStyles({
    root: {
        width: "100%",
        position: "relative",
        margin: spacing(0, 0, 10),
        "& h2": {
            marginTop: spacing(8),
            marginBottom: spacing(2),
            maxWidth: "95vw",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
        "& .MuiContainer-root": {
            margin: spacing(2),
        },
    },
    toc: {
        margin: spacing(12, 0),
        maxWidth: "1440px",
        "& .citation": {
            color: palette.primary.main,
            fontWeight: 300,
            width: "100%",
            textAlign: "center",
            marginTop: spacing(4)
        },
        [breakpoints.up('xs')]: {
            gridTemplateColumns: "1fr",
            maxWidth: "680px",
        },
        [breakpoints.up('lg')]: {
            gridTemplateColumns: "1fr 1fr",
            maxWidth: "1240px"
        },
        [breakpoints.up('xl')]: {
            maxWidth: "1440px"
        },
    },
    jumpTop: {
        [breakpoints.down('md')]: {
            gridRow: 1,
            margin: spacing(4, "auto"),
        },
    },
    jumpUp: {
        [breakpoints.down('md')]: {
            gridRow: 3,
            margin: spacing(4, "auto"),
        },
    },
    banner: {
        display: "inline-block",
        width: "100%",
        height: "20rem",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // background: "url('/images/banners/hand-3044387.jpg')",
        // background: "url('/images/banners/laptop-6465147_1920.jpg')",
        background: "url('/images/banners/expertise.jpg')",
        backgroundPosition: "center",
        margin: spacing(2, 0, 10),
        backgroundColor: palette.primary.main,
        // backgroundBlendMode: "luminosity",
    },
    card: {
        position: "relative",
        overflow: "visible",
        maxWidth: "95vw",
        margin: spacing(6, 3),
        paddingBottom: spacing(4),
        "& .title": {
            marginLeft: spacing(-2),
            maxWidth: "60%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            [breakpoints.down("md")]: {
                maxWidth: "100%",
            }
        },

        "& .emphasize": {
            color: palette.primary.main,
            fontWeight: 400,
        },
        "&  .MuiCardContent-root": {
            marginTop: spacing(6),
            [breakpoints.down('md')]: {
                marginTop: "25vw",
            },
        },
        "&  .teaser": {
            position: "absolute",
            right: -16,
            top: "-3.5rem",
            width: "45%",
            height: "40%",
            boxShadow: "0 0.0em 0.35em 0.0em #666",
            borderRadius: "0.7rem",
            backgroundColor: palette.background.paper,
            backgroundSize: "contain",
            // backgroundBlendMode: "luminosity",
            [breakpoints.down('md')]: {
                right: "2%",
                top: "-3.5rem",
                width: "96%",
                height: "30vw",
            },
        },
        "& .more": {
            position: "absolute",
            bottom: spacing(1),
            right: spacing(1),
        },
        "& .more > a": {
            textDecoration: "none",
            display: "inline-block",
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            padding: spacing(1, 3),
            width: "inherit",
            border: "none",
            cursor: "pointer",
            marginLeft: "auto",
        },
        "& .more > a:hover": {
            backgroundColor: palette.primary.light,
        }
    },
}),
    {
        name: "Expertise"
    });