import React from 'react';
import { Card, CardContent, CardMedia, makeStyles, Typography } from "@material-ui/core";


const HomeBanner = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root} elevation={0} square>
            <CardMedia
                className={classes.media}
                component="img"
                alt="in4s GmbH - Ihr Partner für die Digitalisierung Ihrer Geschäftsprozesse"
                image="images/banners/partner.jpg"
                title="in4s GmbH - Ihr Partner für die Digitalisierung Ihrer Geschäftsprozesse"
            />
            <CardContent className={classes.content}>
                <Typography variant="h2" className={classes.text}>
                    Ihr Partner für die Digitalisierung Ihrer Geschäftsprozesse
                </Typography>
            </CardContent>
        </Card>
    );
}

export default HomeBanner;


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "100%",
        maxHeight: "100vh",
        margin: 0,
        padding: 0,
        position: "relative"
    },
    media: {
        backgroundSize: "contain"
    },
    content: {
        position: "absolute",
        top: "8%",
        left: "5%",
        width: "40%",
        height: "30%",
        maxWidth: "60vmin"
    },
    text: {
        fontWeight: 500,
        fontSize: "6vmin",
        color: theme.palette.primary.contrastText,
    }
}),{
    name: "HomeBanner"
});