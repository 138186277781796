import { Box, Card, CardMedia, createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const Technologies = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}
            display="grid"
            gridGap={4}
            gridTemplateColumns={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"}
        >
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/languages/typescript.png" title="TypeScript"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/languages/node.png" title="NodeJS"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/languages/java.png" title="Java"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/languages/python.png" title="Python"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/languages/cpp.png" title="C++"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/languages/deno.png" title="Deno"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/languages/html.png" title="HTML"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/database/mongodb.png" title="MongoDB"/></Card>

            <Card elevation={0} square><CardMedia image="/images/technologies/database/postgres.png" title="PostgreSQL"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/database/neo4j.png" title="Neo4J"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/database/sqlite.png" title="SQLite"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/database/elastic.png" title="ElasticSearch"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/database/lucene.png" title="Lucene"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/frontend/react.png" title="React"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/frontend/angular.png" title="Angular"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/frontend/jsf.png" title="Java Server Faces"/></Card>

            <Card elevation={0} square><CardMedia image="/images/technologies/container/docker.png" title="Docker"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/container/kubernetes.png" title="Kubernetes"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/container/jenkins.png" title="Jenkins"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/container/aws.png" title="Amazone Web Services"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/container/otc.png" title="Open Telekom Cloud"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/ml/scikit.png" title="Scikit Learn"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/ml/tensorflow.png" title="Tensorflow"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/ml/opencv.png" title="OpenCV"/></Card>

            <Card elevation={0} square><CardMedia image="/images/technologies/ml/opengl.png" title="OpenGL"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/ml/webgl.png" title="WebGL"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/misc/rest.png" title="RESTFul Service Architecture"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/misc/graphql.png" title="GraphQL"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/misc/nest.png" title="NestJS"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/misc/puppeteer.png" title="Puppeteer"/></Card>
            <Card elevation={0} square><CardMedia image="/images/technologies/misc/vscode.png" title="Visual Studio Code"/></Card>
            <Card className={classes.hidden} elevation={0} square><CardMedia image="/images/technologies/misc/gitlab.png" title="Gitlab"/></Card>
        </Box>
    )
}


export default Technologies;

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => createStyles({
    root: {
        backgroundColor: "transparent",
        padding: spacing(0.5),
        "& .MuiCard-root": {
            border: "1px solid",
            borderColor: palette.grey[300],
            backgroundColor: palette.grey[200],
            width: "10rem",
            height: "7.5rem",
            padding: spacing(2),
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
        },
        "& .MuiCardMedia-root": {
            // backgroundColor: palette.common.white,
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
        },
        [breakpoints.up('xs')]: {
            gridTemplateColumns: "1fr",
        },
        [breakpoints.up('sm')]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [breakpoints.up('md')]: {
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
        },
        [breakpoints.up('lg')]: {
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
        },
        [breakpoints.up('xl')]: {
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
        },
    },
    hidden: {
        [breakpoints.down('sm')]: {
            display: "none",
        },
    }
}),
{
    name: "TechStack"
});