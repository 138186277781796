import { FC } from "react";
import * as PropTypes from "prop-types";
import useTranslate from "./useTranslate";

interface TitleProps {
    className?: string;
    skipTitle?: boolean;
    skipAppBar?: boolean;
    title: string;
}

const Title: FC<TitleProps> = ({
    title,
    className,
    skipTitle,
    skipAppBar,
    ...rest
}) => {
    const translate = useTranslate();

    if(!title || !document) return null;

    const container = document.getElementById('header-title');

    if (!container) return null;

    const tt = translate(title, { _: title });

    (!!skipTitle) || (document.title = tt);

    (!!skipAppBar) || (container.textContent = tt);

    return null;

}

Title.propTypes = {
    className: PropTypes.string,
    skipTitle: PropTypes.bool,
    skipAppBar: PropTypes.bool,
};

export default Title;