import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import Title from "../../common/Title";

const ImpressumPage = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" >
            <Title title='in4s GmbH - Impressum' />
            <Box className={classNames(classes.root)}>
                <Typography variant="h3" color="primary">Impressum</Typography>
                <Typography paragraph>Sie befinden sich auf der Website von in4s GmbH. Auf dieser Website finden Sie Angaben zu unseren Dienstleistungen sowie Informationen zu unseren Themengebieten. Sie haben außerdem die Möglichkeit Kontakt mit uns aufzunehmen.</Typography>
                <Typography paragraph>in4s ist verantwortlich für Inhalt und Richtigkeit dieser Website.</Typography>

                <Typography variant="h5" color="primary">Anschrift</Typography>

                <Typography variant="body1">in4s GmbH</Typography>
                <Typography variant="body1">Hegelstraße 39</Typography>
                <Typography variant="body1">39104 Magdeburg</Typography>

                <Typography variant="h5" color="primary">Geschäftsführer</Typography>
                <Typography variant="body1">Dipl.-Inform. Marcel Genzmehr</Typography>
                <Typography variant="body1">Dipl.-Inform. Stefan Langer</Typography>


                <Typography variant="h5" color="primary">Kontakt</Typography>
                <Typography variant="body1">E‑Mail: info@in4s.de</Typography>
                <Typography variant="body1">Web: www.in4s.de</Typography>





                <Typography variant="body1">Amtsgericht Stendal</Typography>
                <Typography variant="body1">HRB 22003</Typography>
                <Typography variant="body1">USt-IdNr.: DE298694858</Typography>
                <Typography variant="body1">Sitz: Magdeburg</Typography>

                <Typography variant="h5" color="primary" >Disclaimer</Typography>

                <Typography variant="body1" paragraph>
                    Die Erstellung der Inhalte dieser Webseite erfolgte mit größtmöglicher Sorgfalt.
                    Allerdings können wir keine Gewähr für die Vollständigkeit der bereitgestellten Inhalte, ihre Aktualität und Richtigkeit sowie den jederzeitigen störungsfreien Zugang übernehmen.
                    Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen,
                    die auf eine rechtswidrige Tätigkeit hinweisen. Jeglicher Rechts- oder Ersatzanspruch aufgrund falscher Darstellungen ist ausgeschlossen.
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                    Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </Typography>

                <Typography variant="h5" color="primary" >Haftung für Links</Typography>

                <Typography variant="body1" paragraph>
                    Diese Website enthält Verweise zu Websites Dritter (externe Links).
                    Mit dem Bestätigen des Verweises verlassen Sie das Informationsangebot der in4s GmbH.
                    Für das Angebot Dritter können daher abweichende Regelungen gelten, insbesondere hinsichtlich des Datenschutzes.
                    Bei den verlinkten externen Seiten handelt es sich ausschließlich um fremde Inhalte, für die wir keine Haftung übernehmen und deren Inhalt wir uns nicht zu Eigen machen.
                    Zwar wurden diese Inhalte vor Verlinkung auf Rechts- und Sittenwidrigkeit überprüft, es ist jedoch nicht auszuschließen, dass Inhalte im Nachhinein von den Anbietern verändert werden.
                    Wir sind nicht dazu verpflichtet, die verlinkten Seiten ständig auf Veränderungen zu überprüfen, da dies ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar ist.
                    Sollten Ihnen dennoch Rechtsverstöße oder sonstige unangemessenen Inhalte auf verlinkten Seiten auffallen, bitten wir Sie um entsprechenden Hinweis.
                    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    Zudem distanzieren wir uns ausdrücklich von rechts- und sittenwidrigen Internetinhalten.
                </Typography>

                <Typography variant="h5" color="primary" >Urheberrecht</Typography>

                <Typography variant="body1" paragraph>
                    Alle Inhalte dieses Angebots wie Bilder, Grafiken, Symbole und Texte unterliegen dem deutschen Urheberrecht.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                    Wenn nicht anders angegeben, liegt das Urheberrecht bei der in4s GmbH. Jegliches Kopieren, Vervielfältigen und Verwenden von Daten dieser Seiten ist ohne ausdrückliche schriftliche Genehmigung der in4s GmbH verboten.
                    Dies gilt auch für die Speicherung und/oder Vervielfältigung in Datenbanken und auf Datenträgern.
                </Typography>
                <Typography variant="body1" paragraph>
                    Hier finden Sie die  Bildquellen der Logos für Softwareentwicklungwerkzeuge, Plattformen etc., die wir auf unserer Seite https://www.in4s.de verwenden:
                </Typography>

                <Typography variant="body1" paragraph>
                    <Accordion>
                        <AccordionSummary
                            // expandIcon={<img src="" /> />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="button" color="primary" >Bildnachweis</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/2560px-Node.js_logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/de/thumb/e/e1/Java-Logo.svg/800px-Java-Logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/1280px-Typescript_logo_2020.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Deno_2021.svg/1280px-Deno_2021.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Python_logo_and_wordmark.svg/729px-Python_logo_and_wordmark.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1280px-ISO_C%2B%2B_Logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/1280px-Angular_full_color_logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1920px-React-icon.svg.png</li>
                                    <li>https://de.wikipedia.org/wiki/Jakarta_Server_Faces#/media/Datei:Jakarta_Server_Faces_logo.tiff</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/225px-MongoDB_Logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/de/thumb/4/4b/Postgresql.svg/300px-Postgresql.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/e/e5/Neo4j-logo_color.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/SQLite370.svg/2560px-SQLite370.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/en/thumb/9/97/Elastic_NV_logo.svg/1280px-Elastic_NV_logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Apache_Lucene_logo_%28pre_2021%29.svg/512px-Apache_Lucene_logo_%28pre_2021%29.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Docker_%28container_engine%29_logo.svg/610px-Docker_%28container_engine%29_logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Kubernetes_logo.svg/798px-Kubernetes_logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Jenkins_logo_with_title.svg/1200px-Jenkins_logo_with_title.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/375px-Amazon_Web_Services_Logo.svg.png</li>
                                    <li>https://de.wikipedia.org/wiki/Open_Telekom_Cloud#/media/Datei:Otc-logo.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Scikit_learn_logo_small.svg/225px-Scikit_learn_logo_small.svg.png</li>
                                    <li>https://de.wikipedia.org/wiki/Datei:TensorFlowLogo.svg</li>
                                    <li>https://de.wikipedia.org/wiki/Datei:OpenCV_Logo_with_text.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Opengl-logo.svg/375px-Opengl-logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/WebGL_Logo.svg/300px-WebGL_Logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/338px-GraphQL_Logo.svg.png</li>
                                    <li>https://docs.nestjs.com/assets/logo-small.svg</li>
                                    <li>https://user-images.githubusercontent.com/10379601/29446482-04f7036a-841f-11e7-9872-91d1fc2ea683.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/GitLab_logo.svg/512px-GitLab_logo.svg.png</li>
                                    <li>https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/330px-HTML5_logo_and_wordmark.svg.png</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Typography>

                <Typography variant="body1" paragraph>
                    Die in4s GmbH garantiert nicht für die Richtigkeit und Vollständigkeit der Daten.
                    Wir bitten im Interesse aller Besucher darum, bei versehentlicher Veröffentlichung falscher Daten diese Fehler mitzuteilen.
                    Die ständige Verfügbarkeit und regelmäßige Aktualisierung dieses Angebots können nicht garantiert werden.
                </Typography>

                <Typography variant="h5" color="primary" >Datenschutz</Typography>
                <Typography variant="body1" paragraph>
                    Wir freuen uns über Ihr Interesse an unserer Homepage und unserem Unternehmen.
                    Der Schutz Ihrer personenbezogenen Daten bei der Erhebung, Verarbeitung und Nutzung anlässlich Ihres Besuchs auf unserer Homepage ist uns ein wichtiges Anliegen.
                    Weitere Informationen zum Datenschutz finden Sie in unserer <NavLink to="/datenschutz">Datenschutzerklärung</NavLink>.
                </Typography>

            </Box>
        </Container>
    )
}


export default ImpressumPage;

const useStyles = makeStyles(({ palette, spacing }) => createStyles({
    root: {
        "& h5": {
            margin: spacing(2, 0),
        }
    },
}),
{
    name: "ImpressumPage",
});