import { createTheme } from "@material-ui/core";
// import { red } from '@mui/material/colors';


// A custom theme for this app
const defaultTheme = createTheme({
  palette: {
      type: "light",
      primary: {
        light: '#448491', //0d8295
        main: '#095763',
        dark: '#0d8295',
        contrastText: '#fff'
      },
      secondary: {
        light: '#e35183',
        main: '#ad1457',
        dark: '#78002e',
        contrastText: '#fff'
      },
  },
  typography: {
      fontFamily: "'Source Sans Pro', sans-serif",
      body1: {
          fontWeight: 300,
          fontSize: "1.5rem",
      },
      h1: {
        fontWeight: 200,
      },
      h2: {
        fontWeight: 200,
      },
      h3: {
        fontWeight: 200,
      },
  },
  overrides: {
      MuiFilledInput: {
          root: {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              '&$disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
          },
      },
      MuiButtonBase: {
          root: {
              '&:hover:active::after': {
                  // recreate a static ripple color
                  // use the currentColor to make it work both for outlined and contained buttons
                  // but to dim the background without dimming the text,
                  // put another element on top with a limited opacity
                  content: '""',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  backgroundColor: 'currentColor',
                  opacity: 0.3,
                  borderRadius: 'inherit',
              },
          },
      },
  },
  props: {
      MuiButtonBase: {
          // disable ripple for perf reasons
          disableRipple: true,
      },
  }
});

export default defaultTheme;

