import React from "react";
import { Box, Card, CardContent, CardMedia, createStyles, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";

const VisDokumente = () => {
    const classes = useStyles();
    return (
        <Box className={classNames(classes.root)}>
            <Card className={classNames(classes.mediaLeft)} elevation={0}>
                <CardContent className="media">
                    <CardMedia className={classNames(classes.demo1)} image="/images/iip/fehlererkennung.png" >
                        <div className={classNames("", "zoomedIn")} />
                    </CardMedia>
                </CardContent>
                <CardContent>
                    <Typography variant="h5">Anomalie- und Anomaliepositionserkennung</Typography>
                    <Typography paragraph>
                        Mit unserer Hilfe finden Sie kleinste Anomalien bei der Verarbeitung von Objekten.
                    </Typography>
                    <Typography paragraph>
                        Ob Kratzer, Löcher oder Risse in Oberflächen:
                        Mit der Hilfe von industriellen Kameras und unserer Technologie automatisieren Sie die Qualitätskontrolle Ihrer Produktionskette.
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.mediaRight)} elevation={0}>
                <CardContent className="media">
                    <div className="framing">
                    <CardMedia className={classNames(classes.demo2)} image="/images/iip/objekterkennung.png">
                        <div /><div /><div /><div /><div /><div /><div /><div />
                    </CardMedia>
                    <CardMedia className={classNames("diag", classes.demo3)} image="/images/iip/kantenerkennung.png" />
                    </div>
                </CardContent>
                <CardContent>
                    <Typography variant="h5">Kanten- und Objekterkennung</Typography>
                    <Typography paragraph>
                        Auch die Umrisse komplexer Bauteile lassen sich mit der Hilfe unserer Algorithmen erkennen. Auf diese Weise können ihre
                        Maschinen in nachfolgenden Produktionsschritten beispielsweise entlang von Kanten oder um bestimmte Teile ihres Produkts herum arbeiten.
                    </Typography>
                </CardContent>
            </Card>

        </Box>
    )
};

export default VisDokumente;

const useStyles = makeStyles(({ palette, spacing, breakpoints}) => createStyles({
    root: {
        backgroundColor: palette.background.default,
        borderColor: palette.primary.dark,
        width: "100%",
        margin: spacing(1, 0),
        padding: spacing(2, 0),
        "& h5": {
            margin: spacing(2, 0),
            color: palette.primary.main,
            textTransform: "uppercase",
            textAlign: "center",
            width: "70rem",
            maxWidth: "90%",
            [breakpoints.down("md")]: {
                margin: spacing(2, "auto"),
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
        "& .MuiTypography-body1": {
            padding: spacing(0, 6),
            color: palette.text.secondary,
            width: "70rem",
            maxWidth: "90%",
            margin: 0,
            [breakpoints.down("md")]: {
                margin: spacing(0, "auto"),
            },
        },
    },
    demo1: {
        marginLeft: "auto",
        "& .zoomedIn": {
            position: "absolute",
            content: "''",
            borderRadius: "50%",
            width: 160,
            height: 160,
            border: "2px solid",
            borderColor: palette.primary.main,
            bottom: -60,
            backgroundImage: "url('/images/iip/zoomIn.jpg')",
            backgroundPosition: "-152px -225px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "300%",
        },
        "&:before": {
            position: "absolute",
            content: "''",
            borderRadius: "50%",
            border: "2px solid",
            borderColor: palette.primary.main,
            width: 16,
            height: 16,
            top: 98,
            left: 110,
        },
        "&:after": {
            position: "absolute",
            content: "''",
            borderRadius: "50%",
            border: "2px solid",
            borderColor: palette.primary.light,
            width: 16,
            height: 16,
            top: 98,
            left: 360,
        },
        [breakpoints.down("md")]: {
            width: 690,
            margin: "0 auto",
        }
    },
    demo2: {
        "& div": {
            position: "absolute",
            border: "2px solid",
            borderColor: "#0c0",
            width: 16,
            height: 16,
        },
        "& div:nth-child(1)": {
            top: 3,
            left: 104,
        },
        "& div:nth-child(2)": {
            top: 33,
            left: 127,
        },
        "& div:nth-child(3)": {
            top: 66,
            left: 106,
        },
        "& div:nth-child(4)": {
            top: 96,
            left: 126,
        },
        "& div:nth-child(5)": {
            top: 136,
            left: 106,
        },
        "& div:nth-child(6)": {
            top: 166,
            left: 126,
        },
        "& div:nth-child(7)": {
            top: 206,
            left: 106,
        },
        "& div:nth-child(8)": {
            top: 236,
            left: 126,
        },
    },
    demo3: {
        "&:before": {
            position: "absolute",
            content: "''",
            border: "2px solid",
            borderColor: "#0c0",
            width: 112,
            height: 76,
            top: 80,
            left: 69,
        },
    },
    mediaLeft: {
        display: "flex",
        flexDirection: "row",
        "& .MuiCardMedia-root, & .MuiCardContent-root": {
            width: "50%",
            height: "28rem",
            [breakpoints.down("md")]: {
                width: "100%",
            }
        },
        "& .MuiTypography-body1, & h5": {
            marginRight: "auto",
        },
        "& .media": {
            backgroundColor: palette.grey[300],
            padding: spacing(8),
            position: "relative",
            paddingLeft: `calc(50% - 685px - ( 2 * ${spacing(8)}px))`,
            overflow: "hidden",
            [breakpoints.down("md")]: {
                height: "28rem",
                paddingLeft: spacing(8),
            },
        },
        "& .framing": {
            position: "relative",
        },
        "& .media .MuiCardMedia-root": {
            width: "500px",
            height: "254px",
            backgroundSize: "contain",
            position: "relative",
            borderRadius: "8px",
            boxShadow: "0 0.0em 0.35em 0.0em #666"
        },
        [breakpoints.down("md")]: {
            flexDirection: "column",
            "& .MuiCardContent-root": {
                height: "inherit",
            },
        }
    },
    mediaRight: {
        display: "flex",
        flexDirection: "row-reverse",
        "& .MuiCardMedia-root, & .MuiCardContent-root": {
            width: "50%",
            height: "28rem",
            [breakpoints.down("md")]: {
                width: "100%",
            }
        },
        "& .MuiTypography-body1, & h5": {
            marginLeft: "auto",
        },
        "& .media": {
            backgroundColor: palette.grey[300],
            padding: spacing(8),
            position: "relative",
            paddingRight: `calc(50% - 685px - ( 2 * ${spacing(8)}px))`,
            [breakpoints.down("md")]: {
                height: "28rem",
                paddingRight: spacing(8),
            },
        },
        "& .framing": {
            position: "relative",
            [breakpoints.down("md")]: {
                width: 690,
                margin: "0 auto",
            }
        },
        "& .media .MuiCardMedia-root": {
            width: "500px",
            height: "254px",
            backgroundSize: "contain",
            position: "relative",
            borderRadius: "8px",
            boxShadow: "0 0.0em 0.35em 0.0em #666"
        },
        "& .media .MuiCardMedia-root.diag": {
            position: "absolute",
            top: "85px",
            left: "185px",
            zIndex: 2,
        },
        [breakpoints.down("md")]: {
            flexDirection: "column",
            "& .MuiCardContent-root": {
                height: "inherit",
            },
        }
    }
}),
{
    name: "ExpertiseVis",
});