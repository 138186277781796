import React from 'react';
import { Card, CardContent, CardMedia, makeStyles, Typography } from "@material-ui/core";

const MoreWithYourData = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root} elevation={0} square>
            <CardMedia
                className={classes.media}
                image="images/start_page.jpg"
            />
            <CardContent className={classes.content} >
                <Typography variant="h3" color='primary' className={classes.headline}>
                Mit uns machen Sie <br />mehr aus Ihren Daten
                </Typography>
                <Typography color='textSecondary' variant='body1'>
                In Ihren Daten steckt oft mehr als Sie denken - egal ob es sich um Bilder oder Texte handelt. Wir sind Profis darin, diese versteckten Potentiale zu heben.
                </Typography>
                <Typography color='textSecondary' variant='body1'>
                Unser Fokus liegt dabei stets auf den <em>Prozessen Ihres Unternehmens</em>. Jede IT-Komponente soll sich nahtlos in Ihre Infrastruktur einfügen und bestmöglich unterstützen.
                Gemeinsam optimieren wir mit Ihnen Ihre Workflows, reduzieren Ihren Aufwand und erhöhen dadurch Ihre Profitabilität.
                </Typography>
                <Typography color='textSecondary' variant='body1'>
                    Neben der reinen Softwareentwicklung beraten wir Sie zu aktuelle und zukünftigen Fragestellungen.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default MoreWithYourData;

const useStyles = makeStyles(({spacing, palette, breakpoints}) => ({
    root: {
        margin: spacing(6, "auto"),
        display: "flex",
        width: "100%",
        maxWidth: 1280,
        // backgroundImage: "url('images/workplace.png')",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "45%",
        // backgroundBlendMode: "luminosity"
        [breakpoints.down('sm')]: {
            flexDirection: "column-reverse",
        }
    },
    media: {
        width: "45%",
        backgroundColor: palette.background.paper,
        // backgroundBlendMode: "luminosity",
        [breakpoints.down('sm')]: {
            width: "100%",
            height: "16rem",
        },
        backgroundPositionX: "center",
    },
    content: {
        maxWidth: "55%",
        // margin: spacing("1.5rem", "0.5rem"),
        marginLeft: spacing(3),
        [breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },
    headline: {
        fontWeight: 400,
        margin: spacing(1, 0, 3),
    },
}),{
    name: "HomeData"
});