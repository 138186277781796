import React from "react";
import { Box, Typography } from "@material-ui/core";


const ErrorPage = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" m={1} p={1}>
                <Typography color="error">Page does not exist!</Typography>
        </Box>
    );
}

export default ErrorPage;