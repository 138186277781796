import { Box, Container, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import Title from "../../common/Title";

const DatenschutzPage = () => {
    // eslint-disable react/jsx-no-target-blank
    return (
        <Container maxWidth="lg">
            <Title title='in4s GmbH - Datenschutzerklärung' />
            <Box >
                <Typography variant="h3" color="primary">Datenschutzerklärung</Typography>
                <Typography variant="body1" color="textPrimary" paragraph>
                    Wir freuen uns über Ihr Interesse an unserer Homepage und unserem Unternehmen.
                    Der Schutz Ihrer personenbezogenen Daten bei der Erhebung,
                    Verarbeitung und Nutzung anlässlich Ihres Besuchs auf unserer Homepage ist uns ein wichtiges Anliegen.
                    Mit dieser Datenschutzerklärung informieren wir Sie, welche Daten wir zu welchen Zwecken erfassen,
                    verarbeiten und speichern.
                </Typography>
                <section className="content section-privacy">
                    <div className="centerbox">

                        <h2>Im Überblick</h2>

                        <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze und der EU-Datenschutzgrundverordnung (DSGVO) sind die Geschäftsführer der in4s GmbH, Herr Stefan Langer und Herr Marcel Genzmehr. </p>

                        <ul style={{margin: "1.5em 0"}}>
                            <li style={{paddingTop: "1em"}}>Beim Absenden einer Kontaktnachricht über unser Kontaktformular wird automatisch eine eMail an die Geschäftsführung versandt. Die von Ihnen eingegebenen Daten werden dadurch - wie beim unverschlüsselten eMail-Versand üblich - unter Umständen auch für Dritte einsehbar. Es findet nur eine sog. Transportverschlüsselung statt, und keine Ende-zu-Ende-Verschlüsselung des Inhalts. <a href="#kontaktformular">Mehr Details zum Kontaktformular.</a></li>

                            <li style={{paddingTop: "1em"}}>Ihre eMails werden durch Microsoft Ireland Operations Ltd innerhalb der EU verarbeitet und gespeichert.</li>
                        </ul>

                        <p>Im folgenden Abschnitt &#8222;<a href="#details">Im Detail</a>&#8220; finden Sie weitere Informationen zu Ihren Rechten, den Zwecken der Datenverarbeitung, der Löschung der Daten, und mehr. Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail über das unten aufgeführte <a href="#kontakt">Kontaktformular</a> oder wenden Sie sich direkt an die Geschäftsführung:</p>

                        <p id="opt-out">in4s GmbH<br />Hegelstraße 39<br />39104 Magdeburg</p>

                        <h2 id="details">Im Detail</h2>

                        <h3>Ihre Betroffenenrechte</h3>
                        <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
                        <ul>
                            <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</li>
                            <li>Berichtigung unrichtiger personenbezogener Daten,</li>
                            <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
                            <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,</li>
                            <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
                            <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.</li>
                        </ul>
                        <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
                        <p>Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden.
                            Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung.
                            Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie auf den Seiten des <Link to="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noopener">BfDI</Link>.
                        </p>

                        <h3>Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</h3>
                        <p>Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
                        <ul>
                            <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
                            <li>die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,</li>
                            <li>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,</li>
                        </ul>
                        <p>die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.</p>

                        <h3>Löschung bzw. Sperrung der Daten</h3>
                        <p>Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>

                        <h3>Erfassung allgemeiner Informationen beim Besuch unserer Website</h3>
                        <p>Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen.</p>
                        <p>Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
                        <ul>
                            <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                            <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                            <li>zu weiteren administrativen Zwecken.</li>
                        </ul>
                        <p>Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus den vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.</p>
                        <p>Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>

                        <h3>Cookies</h3>
                        <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine Textdateien, die von einem Websiteserver auf Ihre Festplatte übertragen werden. Hierdurch erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem und Ihre Verbindung zum Internet.</p>
                        <p>Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.</p>
                        <p>In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.</p>
                        <p>Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.</p>
                        <h2>SSL-Verschlüsselung</h2>
                        <p id="kontaktformular">Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>

                        <h3>Kontaktformular</h3>
                        <p>Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer validen E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden personenbezogene Daten gelöscht.</p>

                        <h3>Änderung unserer Datenschutzbestimmungen</h3>
                        <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>

                    </div>
                </section>

            </Box>
        </Container>
    )
}


export default DatenschutzPage;