import React, { useState } from "react"
import { NavLink } from "react-router-dom";
import { Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";

const ContactForm = () => {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const sendMessage = (e: React.FormEvent) => {
        e.preventDefault();
        const website = (document.getElementById("website") as HTMLInputElement)?.value;
        const data = {name, email, message, website};
        const postData = Object.entries(data)
            .map((entry) => `${entry[0]}=${encodeURIComponent(entry[1])}`)
            .join("&")
        console.log("send_message", postData);

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
             },
            body: postData
        };
        fetch('/contact.php', requestOptions)
        .then(async response => {
            // const isJson = response.headers.get('content-type')?.includes('application/json');
            // const data = isJson && await response.json();
            const data = await response.text()

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                return Promise.reject(new Error(`ServerError [${response.status}]: ${data||response.statusText}`));
            }

            if (data === "sent") {
                setSuccessMsg("Ihre Nachricht wurde erfolgreich verschickt!");
                setName("");
                setEmail("");
                setMessage("");
            } else {
                setErrorMsg(data);
            }
        })
        .catch(error => {
            setErrorMsg("Ihre Nachricht konnte nicht versendet werden.");
        });
    }

    return (
        <Container className={classNames(classes.root)}>
            <form id="contact_form" className={classNames(classes.form)} method="POST" onSubmit={sendMessage}>
                <Typography variant="h6" className={classNames(Boolean(errorMsg.length) ? "notify" : "invisible")} id="contact_error">{errorMsg}</Typography>
                <Typography variant="h6" className={classNames(Boolean(successMsg.length) ? "notify" : "invisible")} id="contact_success">{successMsg}</Typography>
                <p>
                    <input type="text" name="name" className="name" id="name" placeholder="Name" value={name} onChange={e => setName(e.target.value)} required />
                </p>
                <p>
                    <input type="text" name="email" className="email" id="email" placeholder="eMail" value={email} onChange={e => setEmail(e.target.value)} required />
                </p>
                <p style={{display:"block",opacity:0.0,width:0,height:0}}>
                    <input type="text" name="website" id="website" placeholder="Website" />
                </p>
                <p>
                    <textarea name="message" className="message" id="message" placeholder="Ihre Nachricht" value={message} onChange={e => setMessage(e.target.value)} />
                </p>

                <p style={{ marginBottom: "0.5em" }}>
                    <input type="submit" className={classNames("MuiButton", "button")} id="contact_send"  value={"Nachricht senden"}/>
                </p>
            </form>
            <Typography>Mit dem Abschicken Ihrer Nachricht stimmen Sie der Verarbeitung dieser Daten gemäß unserer <NavLink to="/datenschutz">Datenschutzerklärung</NavLink> zu.</Typography>

        </Container>
    )
};

export default ContactForm;

const useStyles = makeStyles(({ palette, spacing }) => createStyles({
    root: {
        margin: 0,
        padding: 0,
        "& a": {
            color: palette.primary.main,
            textDecoration: "none",
        },
        "& a:hover": {
            cursor: "pointer",
            textDecoration: "underline",
        }
    },
    form: {
        margin: "1em 0 1em 0",
        marginTop: "1.5em",
        width: "100%",
        "& #contact_error": {
            color: palette.error.main,
        },
        "& #contact_success": {
            color: palette.success.main,
        },
        "& p.notify": {
            margin: spacing(0, 0.5),
            display: "inline-block",
        },
        "& p.invisible": {
            display: "none",
        },
        "& input, & textarea": {
            fontSize: "1.3em",
            color: palette.text.primary,
            backgroundColor: palette.background.default,
            fontFamily: "Helvetica, sans-serif",
            boxShadow: "0 0.0em 0.75em 0.0em #ddd",
            margin: "0.3em 0",
            padding: "0.5em 0.5em 0.5em 2.5em",
            border: "0.15em solid #fff",
            borderRadius: "0.2em",
            width: "100%",
            boxSizing: "border-box",
        },

        "& input:focus,& textarea:focus": {
            outline: "none",
            border: "0.15em solid $blue-color",
        },

        "& textarea": {
            height: "7em",
            resize: "vertical",
        },

        "& .email,& .name,& .message": {
            backgroundRepeat: "no-repeat",
        },

        "& .email": {
            backgroundImage: 'url("/images/email.png")',
            backgroundSize: "auto 0.9em",
            backgroundPosition: "0.65em 0.7em"
        },

        "& .name": {
            backgroundImage: 'url("/images/name.png")',
            backgroundSize: "auto 1.2em",
            backgroundPosition: "0.5em 0.5em"
        },

        "& .message": {
            backgroundImage: 'url("/images/message.png")',
            backgroundSize: "auto 1.2em",
            backgroundPosition: "0.5em 0.5em"
        },
        "& .button": {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            padding: spacing(1),
            borderRadius: "0.4em",
            width: "inherit",
            border: "none",
            cursor: "pointer",
        },
        "& .button:hover": {
            backgroundColor: palette.primary.light,
        }
    }
}),
{
    name: "ContactForm"
});