
const translate = (
    key: string,
    options?: any
): string => {
    let translated = key;
    if(options?._) {
        translated = options._;
    }
    console.warn("translation missing:", key);
    // find key in some sort of translation structure
    return translated;
}

export const useTranslate = () => {
    return translate
}

export default useTranslate;