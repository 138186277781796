import React from 'react';
import { Box, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import classNames from 'classnames';

const Offers = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root} gridGap={0} display="grid" gridTemplateColumns={"1fr 1fr"}>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'><em>Such-</em> und <em>Recherchetechnologien</em></Typography>
                    <Typography variant="body1" color='textSecondary' >Wir sind Expertem für Such- und Recherchetechnologien auf natürlichsprachigen Dokumenten. Wir entwickeln Softwarelösungen für:
                        <ul className={classNames(classes.list)}>
                            <li>anfallende ETL-Prozesse</li>
                            <li>automatisierte Analyse der Daten</li>
                            <li>innovative Rechercheoberflächen</li>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'><em>Bilddatenanalyse</em></Typography>
                    <Typography variant="body1" color='textSecondary' >
                        Langjährige Erfahrung in der industriellen Bildanalyse zur Unterstützung der Qualitätskontrolle mittels:
                        <ul className={classNames(classes.list)}>
                            <li>Einsatz von Industriekameras z.&nbsp;B. von IDS oder Baumer</li>
                            <li>Positionierung mittels Sensoren wie Drehgeber oder Distanzlaser</li>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'><em>Digitalisierung</em> &amp; <em>Automatisierung</em></Typography>
                    <Typography variant="body1" color='textSecondary' >Darüber hinaus digitalisieren und automatisieren wir auch die anderen zeitintensiven Prozesse in Ihrem Unternehmen. So bleibt Ihnen mehr Zeit für die wesentlichen Aufgaben Ihres Geschäfts.</Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'><em>Maschinelles Lernen</em> &amp; <em>KI</em></Typography>
                    <Typography variant="body1" color='textSecondary' >Durch unsere Gründungsgeschichte verfügen wir über besonders enge Kontakte zu wissenschaftlichen Einrichtungen. Uns steht das volle Potential zur automatisierten Analyse Ihrer Daten zur Verfügung.</Typography>
                </CardContent>
            </Card>

        </Box>
    );
}

export default Offers;

const useStyles = makeStyles(({palette, spacing, breakpoints}) => ({
    root: {

        margin: spacing(6, 1, 12),
        [breakpoints.up("xs")]: {
            gridTemplateColumns: "1fr",
            maxWidth: "44rem",
            minHeight: "78rem",
        },
        [breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr 1fr",
            maxWidth: "64rem",
            minHeight: "48rem",
        },
    },
    domain: {
        boxShadow: "0 0.0em 0.75em 0.0em #ddd",
        padding: spacing(4),
        margin: spacing(2),
        "& h5": {
            marginTop: 0,
            marginBottom: spacing(2),
        },
        "& .MuiCardContent-root": {
            padding: 0,
        },
    },
    list: {
        marginTop: spacing(0.5),
    },
    shaded: {
        backgroundColor: palette.grey[100],
    }

}),{
    name: "WeOffer"
});