import { Box, CssBaseline, ThemeProvider } from '@material-ui/core';
// import { createTheme, useMediaQuery } from '@material-ui/core';
import * as React from 'react';

import defaultTheme from "./common/theme";
import Header from './layout/Header';
import Footer from './layout/Footer';
import { Outlet } from 'react-router-dom';




export default function App() {
  // const prefersDarkMode = !useMediaQuery('(prefers-color-scheme: light)');

  // const theme = React.useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         type: prefersDarkMode ? 'dark' : 'light',
  //       },
  //     }),
  //   [prefersDarkMode],
  // );

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
        <Header />
        <Box sx={{ my: 4, p: 0 }}>
          <Outlet />
        </Box>
        <Footer />
    </ThemeProvider>
  );
}
