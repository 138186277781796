import * as React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Box, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import useTranslate from '../common/useTranslate';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/">
                in4s GmbH
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
}

const Footer = () => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Box display={"flex"} flexDirection="column" className={classNames(classes.root, "MuiPaper-elevation4")}>
            <Box display={"flex"} flexDirection="row" className={classNames(classes.content)} flexGrow={1}>
                <Box display={"flex"} flexDirection="column" flexGrow={1}>
                    &nbsp;
                </Box>
                <Box display={"flex"} flexDirection="column" className={classNames(classes.left)} flexGrow={1}>
                    <Typography variant="h6" style={{marginBottom: 4}}>Kontakt</Typography>
                    <Typography variant="body2" >Sie&nbsp;haben&nbsp;Fragen? Wir&nbsp;freuen&nbsp;uns&nbsp;auf&nbsp;Sie!</Typography>
                    {/* <Typography variant="button" style={{display: "flex", alignContent: "center"}}>
                        <PhoneIcon /><span style={{marginLeft: 8}}>+49 (0) Telefonnummer</span>
                    </Typography> */}
                    {/* <Typography variant="body2" style={{marginBottom: 8}}>Wir freuen uns auf Ihren Anruf!</Typography>
                    <Typography variant="body2">Oder schreiben Sie uns:</Typography> */}
                    <Typography variant="button" style={{display: "flex", alignContent: "center"}}>
                        <MailOutlineIcon /><img style={{height: "1.1rem", margin: "auto 8px"}} src="images/contact.png" alt="contact" />
                    </Typography>
                </Box>
                <Box display={"flex"} flexDirection="column" className={classNames(classes.center)} flexGrow={1}>
                    <Typography variant="h6" style={{marginBottom: 4}}>Anfahrt</Typography>
                    <Typography variant="body2">in4s GmbH</Typography>
                    <Typography variant="body2">Hegelstraße 39</Typography>
                    <Typography variant="body2">39104 Magdeburg</Typography>
                    <Typography variant="button" style={{marginTop: 8, display: "inline-flex", alignContent: "center"}} color="textPrimary">
                        <RoomOutlinedIcon />
                        <Link title='Google Maps' href="https://goo.gl/maps/Z6ZPiUB1AafBdoyQ9" color="textPrimary">
                            <span style={{marginLeft: 8}}>Auf Google Maps anzeigen</span>
                        </Link>
                    </Typography>
                </Box>
                <Box display={"flex"} flexDirection="column" className={classNames(classes.right)} flexGrow={1}>
                    <Typography variant="h6" style={{marginBottom: 4}}>Rechtliches</Typography>
                    <Typography variant="button"><NavLink to={"/impressum"} color="textPrimary" >{translate("Impressum", {_: "Impressum"})}</NavLink></Typography>
                    <Typography variant="button"><NavLink to={"/datenschutz"} color="textPrimary" >{translate("Datenschutz", {_: "Datenschutz"})}</NavLink></Typography>
                    <Typography variant="button"><NavLink to={"/contact"} color="textPrimary" >{translate("Kontakt", {_: "Kontakt"})}</NavLink></Typography>
                    {/* <Typography variant="button">Social Media Links</Typography> */}
                </Box>
                <Box display={"flex"} flexDirection="column" flexGrow={1}>
                    &nbsp;
                </Box>
            </Box>
            <Copyright />
        </Box>
    )
}

export default Footer;

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => createStyles({
    root: {
        backgroundColor: "rgba(0,0,0,0.1)",
        // backgroundColor: palette.primary.light,
        position: "absolute",
        left: 0,
        width: "100%",
        maxWidth: "100vw",
    },
    content: {
        padding: spacing(3),
    },
    left: {
        display: "inline-block",
        maxWidth: "24rem",
        padding: spacing(0,4),
        borderRight: "1px solid",
        borderRightStyle: "groove",
        borderRightColor: palette.text.secondary,
        [breakpoints.down(640)]: {
            display: "none",
        }
    },
    center: {
        maxWidth: "24rem",
        padding: spacing(0,4),
        [breakpoints.down(420)]: {
            display: "none",
        },

    },
    right: {
        borderLeft: "1px solid",
        borderLeftStyle: "groove",
        borderLeftColor: palette.text.secondary,
        maxWidth: "24rem",
        padding: spacing(0,4),
        "& a": {
            textDecoration: "none",
            color: "inherit",
        },
        "& a:hover": {
            textDecoration: "underline",
        },
        [breakpoints.down(420)]: {
            border: "none",
        }
    }
}),
{
    name: "Footer"
});