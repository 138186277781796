import React from 'react';
import { Box, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import classNames from 'classnames';

const Offers = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root} gridGap={0} display="grid" gridTemplateColumns={"1fr 1fr"}>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'>Individuelle Softwarelösungen</Typography>
                    <Typography variant="body1" color='textSecondary' >Wir bieten maßgeschneiderte Software-Lösungen für die Digitalisierung in Ihrem Unternehmen.</Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'>Technische Beratung</Typography>
                    <Typography variant="body1" color='textSecondary' >Wir beraten Sie zur Machbarkeit und Zukunftsfähigkeit Ihrer technischen Konzepte und Ideen.</Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'>Nachhaltigkeit</Typography>
                    <Typography variant="body1" color='textSecondary' >Wir achten auf Nachhaltigkeit durch intelligentes Softwaredesign, wiederverwendbare Module und effiziente Resourcennutzung.</Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'>Zuverlässigkeit</Typography>
                    <Typography variant="body1" color='textSecondary' >Wir setzen auf aktuelle und etablierte Technologien um Ihnen eine robuste Basis für Ihre digitalisierte Zukunft zu bieten.</Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'>Exzellentes Projektmanagement</Typography>
                    <Typography variant="body1" color='textSecondary' >Auch in komplexen Projekten mit vielen unternehmensübergreifenden Beteiligten behalten wir stets den Überblick.</Typography>
                </CardContent>
            </Card>

            <Card className={classNames(classes.domain)} elevation={0} square >
                <CardContent>
                    <Typography variant="h5" color='primary'>Seminare und Schulungen</Typography>
                    <Typography variant="body1" color='textSecondary' >Mit unserer starker Bindung zu wissenschaftlichen Einrichtungen bieten wir auch Seminare und Schulungen virtuell oder in Ihrem Haus.</Typography>
                </CardContent>
            </Card>



        </Box>
    );
}

export default Offers;

const useStyles = makeStyles(({palette, spacing, breakpoints}) => ({
    root: {
        width: "74rem",
        height: "47rem",
        margin: spacing(6, 1),
        [breakpoints.down("md")]: {
            maxWidth: "90vw",
            height: "inherit",
        },
        [breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
        },
    },
    domain: {
        "& .MuiCardContent-root": {
            padding: 0,
        },
        boxShadow: "0 0.0em 0.75em 0.0em #ddd",
        padding: spacing(4),
        margin: spacing(2),
    },
    shaded: {
        backgroundColor: palette.grey[100],
    }

}),{
    name: "Erwartungen"
});